<template>
  <div class="grid">
    <v-card class="mb-4">
      <v-card-text>
        <v-row>
          <v-col class="col-12">
            <LineChart
              :items="items"
              date-field="date"
              :series="[
                { data_key: 'revenue', yaxis: true, unit: '$', type: 'line', label: 'Revenue' },
                { data_key: 'uniques', yaxis: true, yaxis_opposite: true, type: 'line', label: 'Uniques' }
              ]"
              xunit="date"
              :xaxis="['2020-01-01', '2020-01-30']"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" mobile-breakpoint="0"> </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart'
import DateHelper from '@/util/DateHelper'

export default {
  components: {
    LineChart
  },
  data() {
    return {
      headers: [
        { text: 'Date', sortable: false, align: 'start', value: 'date' },
        { text: 'Uniques', sortable: false, align: 'right', value: 'uniques' },
        { text: 'Clicks', sortable: false, align: 'right', value: 'clicks' },
        { text: 'CTR', sortable: false, align: 'right', value: 'ctr' },
        { text: 'RPC', sortable: false, align: 'right', value: 'rpc' },
        { text: 'RPM', sortable: false, align: 'right', value: 'rpm' },
        { text: 'Revenue', sortable: false, align: 'right', value: 'revenue' }
      ]
    }
  },
  computed: {
    items() {
      let startDate = new Date('2020-01-01')
      let testItems = []
      for (let i = 0; i < 2000; i++) {
        startDate.setDate(startDate.getDate() + 1)
        let testItem = { date: DateHelper.toDateString(startDate), uniques: 2, revenue: 1 }
        testItems.push(testItem)
      }
      return testItems
    }
  }
}
</script>
